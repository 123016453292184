<template>
    <div>
        <div class="top">
            <div class="title">评价订单</div>
            <div class="order">
                订单号： {{ order.OrderID }} | {{ order.CreatTime }}
            </div>
        </div>
        <div v-for="(item, index) in order.DetailList" :key="index">
            <div class="evaluate-list" v-if="item.NeedEva">
                <div class="evaluate-list-left">
                    <div class="title">商品评价</div>
                    <div class="img">
                        <img :src="pic + item.ProPictrue" alt="" />
                    </div>
                    <div>
                        {{ item.ProName }}
                    </div>
                    <div class="price">
                        ￥
                        {{ order.TotalPrice.toFixed(2) }}
                    </div>
                </div>
                <div class="evaluate-list-right" v-if="item.value">
                    <div class="label">
                        <div class="label-left">综合评分</div>
                        <div class="rate">
                            <div>
                                <el-rate
                                    @change="changeRate(item, index)"
                                    :colors="['#DC2310', '#DC2310', '#dc2310']"
                                    v-model="item['value']"
                                ></el-rate>
                            </div>
                            <div class="rateType">
                                {{ item.label }}
                            </div>
                        </div>
                    </div>
                    <div class="label" v-if="item.EvTip">
                        <div class="label-left">大家都写到</div>
                        <div class="tip">
                            <div
                                @click="changeTip(j)"
                                :class="{ active: j.active }"
                                v-for="(j, i) in item.EvTip"
                                :key="i"
                            >
                                {{ j.Tip }}
                            </div>
                        </div>
                    </div>
                    <div class="label">
                        <div class="label-left">评价晒单</div>
                        <div>
                            <el-input
                                type="textarea"
                                :rows="5"
                                style="width: 400px"
                                placeholder="请输入内容"
                                v-model="item['input']"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="label">
                        <div class="label-left">图片</div>
                        <div class="upload">
                            <div>
                                <el-upload
                                    :limit="5"
                                    :action="url"
                                    list-type="picture-card"
                                    :on-exceed="handleExceed"
                                    :data="{
                                        directory: 'Eva'
                                    }"
                                    :on-success="
                                        (res, file, fileList) =>
                                            handleSuccess(
                                                res,
                                                file,
                                                fileList,
                                                item
                                            )
                                    "
                                    :on-remove="
                                        (file, fileList) =>
                                            handleRemove(file, fileList, item)
                                    "
                                >
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="button">
            <div @click="publish">
                发表
            </div>
        </div>
    </div>
</template>

<script>
import { getOrderDetail, submitEva } from './service'
import { getProductEvTip, getEvaState } from './service'

export default {
    data() {
        return {
            ss: null,
            url: '',
            pic: FILE_URL,
            OrderID: '',
            order: []
        }
    },
    mounted() {
        this.OrderID = this.$route.query.OrderID
        this.init()
        this.url = FILE_URL + '/UploadFile/UploadImg'
    },
    methods: {
        handleExceed() {
            this.$message.warning(`当前限制选择最多上传5个文件`)
        },
        // 评分
        changeRate(data) {
            switch (data.value) {
                case 1:
                    data.label = '失望'
                    break
                case 2:
                    data.label = '不满'
                    break
                case 3:
                    data.label = '一般'
                    break
                case 4:
                    data.label = '满意'
                    break
                case 5:
                    data.label = '惊喜'
                    break
            }
        },
        // 大家都写到
        changeTip(data) {
            data.active = !data.active
            this.order.DetailList.splice(0, 0)
        },
        // 上传图片
        handleSuccess(res, file, fileList, item) {
            item.Photos = fileList
        },
        handleRemove(file, fileList, item) {
            item.Photos = fileList
        },
        // 发表
        publish() {
            let data = {}
            data.OrderID = this.order.OrderID
            data.JsonData = []

            for (const detailListKey in this.order.DetailList) {
                let arr = []
                for (const item in this.order.DetailList[detailListKey]
                    .Photos) {
                    arr.push(
                        this.order.DetailList[detailListKey].Photos[item]
                            .response.data[0].img
                    )
                }
                data.JsonData.push({
                    LogisticsStar: 5,
                    ServerStar: 5,
                    ProductStar: this.order.DetailList[detailListKey].value,
                    Detail: this.order.DetailList[detailListKey].input,
                    Video: '',
                    Photos: arr.length > 0 ? arr.join(',') : '',
                    PID: this.order.DetailList[detailListKey].PID,
                    Tips: this.getTips(
                        this.order.DetailList[detailListKey].EvTip
                    )
                })
            }
            data.JsonData = JSON.stringify(data.JsonData)
            submitEva(data).then(() => {
                this.$message.success('评价成功')
                this.$router.push({
                    path: '/me/order'
                })
            })
        },
        getTips(data) {
            let id = ''
            for (const dataKey in data) {
                if (data[dataKey].active) {
                    id += data[dataKey].ID + ','
                }
            }
            id = id.substr(0, id.length - 1)
            return id
        },
        init() {
            getOrderDetail({
                OrderID: this.OrderID
            }).then(res => {
                for (const detailListKey in res.data.DetailList) {
                    res.data.DetailList[detailListKey].value = 5
                    res.data.DetailList[detailListKey].label = '惊喜'
                    res.data.DetailList[detailListKey].NeedEva = null
                    // 获取商品标签
                    getProductEvTip({
                        PID: res.data.DetailList[detailListKey].PID
                    }).then(ress => {
                        res.data.DetailList[detailListKey].EvTip = ress.data
                        res.data.DetailList.splice(0, 0)
                    })
                }
                this.order = res.data
                // 查看本订单商品是否支持评价
                getEvaState({
                    OrderID: this.OrderID
                }).then(res => {
                    for (const key in res.data) {
                        const element = res.data[key]
                        for (const key in this.order.DetailList) {
                            const s = this.order.DetailList[key]
                            if (element.PID == s.PID) {
                                s.NeedEva = element.NeedEva
                                if (!this.order.DetailList[key].NeedEva) {
                                    // delete this.order.DetailList[key]
                                    this.order.DetailList.splice(key, 1)
                                    // console.log(this.order.DetailList[key])
                                }
                                break
                            }
                        }
                    }
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
.button {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    div {
        color: #ffffff;
        background: #dc2310;
        width: 100px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
    }
}

.tip {
    display: flex;
    font-size: 12px;

    div {
        background: rgba(27, 152, 170, 0);
        border: 1px solid #0097ba;
        color: #0097ba;
        padding: 2px 10px;
        cursor: pointer;
    }

    .active {
        color: #ffffff;
        background: #0097ba;
        border: 1px solid #0097ba;
    }
}

.label-left {
    width: 80px;
    margin-top: 3px;
    font-size: 13px;
    font-family: 'ct';
}

.rate {
    display: flex;
}

.rateType {
    color: #666666;
    font-size: 12px;
    padding: 3px 10px;
    background: #f5f5f5;
}

.top {
    display: flex;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }

    .order {
        font-size: 12px;
        color: #666666;
        margin-top: 5px;
        margin-left: 20px;
    }
}

.evaluate-list {
    display: flex;
    background: rgba(249, 249, 249, 0);
    border: 1px solid #f4f4f4;
    margin-top: 10px;

    .evaluate-list-right {
        padding: 20px 20px;

        .label {
            display: flex;
            margin-bottom: 30px;

            div {
                margin-right: 10px;
            }
        }
    }

    .evaluate-list-left {
        padding: 50px 20px;
        width: 200px;
        text-align: center;
        border-right: 1px solid #f4f4f4;

        .title {
            font-family: 'ct';
        }

        .img {
            width: 100px;
            height: 100px;
            margin: 30px auto 10px auto;
            border: 1px solid #e5e5e5;

            img {
                width: 100px;
                height: 100px;
            }
        }

        .sku {
            width: fit-content;
            padding: 2px 10px;
            background: #f5f5f5;
            border: 1px solid #dedede;
            border-radius: 15px;
            color: #333333;
            font-size: 12px;
            margin: 10px auto;
        }
    }

    .price {
        font-family: 'ct';
        margin-top: 30px;
    }
}
</style>
